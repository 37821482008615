<template>
  <app-filters :filters="form" @submit="$emit('submit', $event)">
    <template #body>
      <b-row>
        <b-col md="3">
          <app-datepicker
              :label="$t('label.date')"
              :placeholder="$t('label.date')"
              :value="form.date"
              @change="form.date = $event"
          />
        </b-col>
      </b-row>
    </template>
  </app-filters>
</template>

<script>
import AppFilters from '@/components/filters/AppFilters';
import AppDatepicker from '@/components/form-groups/AppDatepicker';

export default {
  components: {
    AppDatepicker,
    AppFilters,
  },
  data: () => ({
    form: {
      date: new Date()
    }
  })
}
</script>