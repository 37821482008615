<template>
  <div>
    <filters-form @submit="filters = $event"/>

    <app-card>
      <template #body>
        <app-table
          :request-url="`/api/gateway/list?date=${computedFilters}`"
          :fields="fields"
          :update-data="updateData"
          hide-pagination
        >
          <template #cell(name)="data">{{ data.value || '-' }}</template>
          <template #cell(isOnline)="data">{{ data.value ? $t('status.online') : $t('status.offline') }}</template>
          <template #cell(created_date)="data">{{ data.value || '-' }}</template>
        </app-table>
      </template>
    </app-card>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';
import FiltersForm from './components/FiltersForm.vue';

export default {
  components: {
    FiltersForm,
    AppCard,
    AppTable
  },
  data() {
    return {
      updateData: false,
      filters: null,
      fields: [
        {key: 'name', label: this.$t('label.title')},
        {key: 'isOnline', label: this.$t('label.status')},
        {key: 'created_date', label: this.$t('label.created_at')}
      ]
    }
  },
  computed: {
    computedFilters() {
      let date = this.filters?.date;

      if (!date) {
        const today = new Date();
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      }

      return date;
    }
  },
  watch: {
    updateData(val) {
      if (val) {
        setTimeout(() => this.updateData = false, 700);
      }
    },
    filters: {
      immediate: true,
      handler() {
        this.updateData = true;
      },
      deep: true
    }
  }
}
</script>